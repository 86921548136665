import React, { JSX } from 'react';
import Select from 'react-select';
import { Box } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';

export type CameraOption = {
  value: string;
  label: string;
};

type CameraDeviceSelectProps = {
  devicesSelectOptions: CameraOption[];
  handleSelectDevice: (deviceOption: CameraOption | null) => void;
  selectedOption?: CameraOption;
};

const SwitchCamera = ({
  devicesSelectOptions,
  selectedOption,
  handleSelectDevice,
}: CameraDeviceSelectProps): JSX.Element | null => {
  const translations = useTranslations();

  if (devicesSelectOptions.length > 1) {
    return (
      <Box width="200px">
        <Select
          options={devicesSelectOptions}
          isSearchable={false}
          value={selectedOption}
          getOptionLabel={(option: CameraOption) => option.label}
          getOptionValue={(option: CameraOption) => option.value}
          name={'camera'}
          onChange={handleSelectDevice}
          placeholder={translations('camera_select_camera_placeholder')}
        />
      </Box>
    );
  } else {
    return null;
  }
};

export default SwitchCamera;
