import React, { JSX, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VStack } from '@chakra-ui/react';
import QrScanner from 'qr-scanner';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import SwitchCamera, { CameraOption } from '../SwitchCamera/SwitchCamera';
import { useToast } from '../Toast';

const QR_SCANNER_CAMERA_NOT_FOUND = 'not found';

export type QrCodeScannerProps = {
  onScan: (data: string) => void;
};

const QrCodeScanner = ({ onScan }: QrCodeScannerProps): JSX.Element => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const [devices, setDevices] = useState<QrScanner.Camera[]>([]);
  const [deviceId, setDeviceId] = useLocalStorage('deviceId', '');
  const [pageVisibility, setPageVisibility] = useState(document.visibilityState);

  const getPageVisibilityListener = () => setPageVisibility(document.visibilityState);

  const qrScannerRef = useRef<QrScanner | null>(null);

  const devicesSelectOptions = useMemo(
    () =>
      devices?.map((device) => ({
        value: device.id,
        label: device.label,
      })),
    [devices],
  );

  // FYI: The camera stream does not start in a local environment on the first page load due to an error
  // `The camera stream is only accessible if the page is transferred via https`
  // It works correctly in the development environment
  const getQrScanner = useCallback(
    (videoElement?: HTMLVideoElement) =>
      videoElement
        ? new QrScanner(
            videoElement as HTMLVideoElement,
            (result) => {
              if (result.data) {
                onScan(result.data);
              }
            },
            {
              preferredCamera: deviceId.length ? deviceId : undefined,
              calculateScanRegion: (video) => {
                return {
                  width: video.videoWidth,
                  height: video.videoHeight,
                };
              },
            },
          )
        : null,
    [deviceId, onScan],
  );

  const handleCameraError = useCallback(
    (error: string) =>
      displayToast(
        'error',
        error.includes(QR_SCANNER_CAMERA_NOT_FOUND) ? translations('camera_devices_permission_error') : error,
        false,
        4000,
      ),
    // FYI: displayToast and translations in deps cause endless re-rendering
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', getPageVisibilityListener);
    return () => {
      document.removeEventListener('visibilitychange', getPageVisibilityListener);
      qrScannerRef.current?.stop();
      qrScannerRef.current?.destroy();
    };
  }, []);

  const stopMediaStreamTracks = () => {
    const videoElement = document.getElementById('video') as HTMLVideoElement;
    const mediaStream = videoElement.srcObject;
    if (mediaStream instanceof MediaStream) {
      const tracks = mediaStream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      videoElement.srcObject = null;
    }
  };

  useEffect(() => {
    if (pageVisibility === 'hidden') {
      stopMediaStreamTracks();
      qrScannerRef.current?.stop();
      qrScannerRef.current?.destroy();
      qrScannerRef.current = null;
    } else {
      const videoElement = document.getElementById('video') as HTMLVideoElement;
      if (!qrScannerRef.current) {
        qrScannerRef.current = getQrScanner(videoElement);
        qrScannerRef.current
          ?.start()
          .then(() => {
            QrScanner.listCameras(true).then((cameras) => {
              setDevices(cameras);
            });
          })
          .catch(handleCameraError);
      }
    }
  }, [getQrScanner, pageVisibility, handleCameraError]);

  const handleSelectDevice = (deviceOption: CameraOption | null) => {
    if (deviceOption) {
      setDeviceId(deviceOption.value);
      qrScannerRef.current?.setCamera(deviceOption.value);
    }
  };

  return (
    <VStack my="auto">
      <video id="video" width="500" />
      <SwitchCamera
        selectedOption={devicesSelectOptions.find((option) => option.value === deviceId)}
        devicesSelectOptions={devicesSelectOptions}
        handleSelectDevice={handleSelectDevice}
      />
    </VStack>
  );
};

export default QrCodeScanner;
