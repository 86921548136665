import React from 'react';
import { Role } from 'contexts/SessionContext';
import AboutUs from 'modules/AboutUs/AboutUs';
import CreateAboutUs from 'modules/AboutUs/CreateAboutUs';
import UpdateAboutUs from 'modules/AboutUs/UpdateAboutUs';
import ApplicationUsersPage from 'modules/ApplicationUsers/ApplicationUsersPage';
import ExtendAssignedItem from 'modules/AssignedItems/ExtendAssignedItem';
import UsersAssignedItemsPage from 'modules/AssignedItems/UsersAssignedItemsPage';
import Categories from 'modules/Categories/Categories';
import CreateCategory from 'modules/Categories/CreateCategory';
import UpdateCategory from 'modules/Categories/UpdateCategory';
import CitiesPage from 'modules/City/CitiesPage';
import Corporates from 'modules/Corporates/Corporates';
import CreateCorporate from 'modules/Corporates/CreateCorporate';
import UpdateCorporate from 'modules/Corporates/UpdateCorporate';
import Home from 'modules/Home';
import CreateKeyAccountGroup from 'modules/KeyAccountGroups/CreateKeyAccountGroup';
import KeyAccountGroupEdit from 'modules/KeyAccountGroups/KeyAccountGroupEdit';
import KeyAccountGroupAddRestaurant from 'modules/KeyAccountGroups/KeyAccountGroupRestaurants/components/AddRestaurant/KeyAccountGroupAddRestaurant';
import KeyAccountGroupRestaurants from 'modules/KeyAccountGroups/KeyAccountGroupRestaurants/KeyAccountGroupRestaurants';
import KeyAccountGroups from 'modules/KeyAccountGroups/KeyAccountGroups';
import CreateNews from 'modules/News/CreateNews';
import News from 'modules/News/News';
import UpdateNews from 'modules/News/UpdateNews';
import SendNotifications from 'modules/Notifications/SendNotifications';
import CreatePartner from 'modules/Partners/CreatePartner';
import Partners from 'modules/Partners/Partners';
import UpdatePartner from 'modules/Partners/UpdatePartner';
import PaymentsPage from 'modules/Payments/PaymentsPage';
import GenerateProducts from 'modules/Products/GenerateProducts/GenerateProducts';
import Products from 'modules/Products/Products';
import PromotionFormPage from 'modules/Promotions/PromotionFormPage';
import PromotionsPage from 'modules/Promotions/PromotionsPage';
import CreateRelevoUser from 'modules/RelevoUsers/CreateRelevoUser';
import RelevoUserPage from 'modules/RelevoUsers/RelevoUserPage';
import RelevoUsers from 'modules/RelevoUsers/RelevoUsers';
import CreateRestaurantUser from 'modules/RestaurantCredentials/CreateRestaurantUser';
import RestaurantUsers from 'modules/RestaurantCredentials/RestaurantUsers';
import CreateRestaurant from 'modules/Restaurants/CreateRestaurant';
import UpdateRestaurant from 'modules/Restaurants/UpdateRestaurant';
import CreateTag from 'modules/Tags/CreateTag';
import Tags from 'modules/Tags/Tags';
import UpdateTag from 'modules/Tags/UpdateTag';
import CreateWarehouse from 'modules/Warehouses/CreateWarehouse';
import UpdateWarehouse from 'modules/Warehouses/UpdateWarehouse';
import Warehouses from 'modules/Warehouses/Warehouses';

import ApplicationUserProfileSettings from '../modules/ApplicationUsers/ApplicationUserProfileSettings/ApplicationUserProfileSettings';
import CreateCity from '../modules/City/CreateCity';
import UpdateCity from '../modules/City/UpdateCity';
import ClosingSources from '../modules/ClosingSources/ClosingSources';
import CreateClosingSource from '../modules/ClosingSources/CreateClosingSource';
import UpdateClosingSource from '../modules/ClosingSources/UpdateClosingSource';
import FeatureFlagsPage from '../modules/FeatureFlags/FeatureFlagsPage';
import FrequentlyAskedQuestionsPage from '../modules/FrequentlyAskedQuestions/FrequentlyAskedQuestionsPage';
import QuestionCreate from '../modules/FrequentlyAskedQuestions/QuestionCreate';
import QuestionUpdate from '../modules/FrequentlyAskedQuestions/QuestionUpdate';
import SectionCreate from '../modules/FrequentlyAskedQuestions/SectionCreate';
import SectionUpdate from '../modules/FrequentlyAskedQuestions/SectionUpdate';
import KeyAccountGroupImages from '../modules/KeyAccountGroups/KeyAccountGroupImages/KeyAccountGroupImages';
import { KeyAccountGroupReturnCodes } from '../modules/KeyAccountGroups/KeyAccountGroupReturnCodes/KeyAccountGroupReturnCodes';
import LostItems from '../modules/LostItems/LostItems';
import CreateMetaCategory from '../modules/MetaCategories/CreateMetaCategory';
import MetaCategories from '../modules/MetaCategories/MetaCategories';
import UpdateMetaCategory from '../modules/MetaCategories/UpdateMetaCategory';
import SendTestNotification from '../modules/Notifications/TestNotifications/SendTestNotification';
import CreatePartnersBatch from '../modules/Partners/CreatePartnersBatch';
import AssignProduct from '../modules/ProductAssignment/AssignProduct';
import Reports from '../modules/Reports/Reports';
import { NotificationSettings } from '../modules/RestaurantCredentials/NotificationSettings/NotificationSettings';
import RestaurantUserPage from '../modules/RestaurantCredentials/RestaurantUserPage';
import Customers from '../modules/Restaurants/Customers/Customers';
import { CustomerSummary } from '../modules/Restaurants/Customers/CustomerSummary';
import UpdateCustomer from '../modules/Restaurants/Customers/UpdateCustomer';
import { Deposit } from '../modules/Restaurants/Deposit/Deposit';
import ImportRestaurants from '../modules/Restaurants/ImportRestaurants/ImportRestaurants';
import { RestaurantDraft } from '../modules/Restaurants/RestaurantDrafts/RestaurantDraft/RestaurantDraft';
import RestaurantDrafts from '../modules/Restaurants/RestaurantDrafts/RestaurantDrafts';
import RestaurantImages from '../modules/Restaurants/RestaurantImages/RestaurantImages';
import CreateRestaurantReturnCode from '../modules/Restaurants/RestaurantReturnCodes/components/CreateRestaurantReturnCode/CreateRestaurantReturnCode';
import { DownloadRestaurantReturnCode } from '../modules/Restaurants/RestaurantReturnCodes/components/DownloadRestaurantReturnCode/DownloadRestaurantReturnCode';
import UpdateRestaurantReturnCode from '../modules/Restaurants/RestaurantReturnCodes/components/UpdateRestaurantReturnCode/UpdateRestaurantReturnCode';
import RestaurantReturnCodes from '../modules/Restaurants/RestaurantReturnCodes/RestaurantReturnCodes';
import Restaurants from '../modules/Restaurants/Restaurants';
import { TwoFactorReturn } from '../modules/Restaurants/TwoFactorReturn/TwoFactorReturn';
import UpdateMultipleRestaurants from '../modules/Restaurants/UpdateMultipleRestaurants/UpdateMultipleRestaurants';
import { CreateReuseCards } from '../modules/ReuseCards/components/CreateReuseCards/CreateReuseCards';
import { ReuseCardItems } from '../modules/ReuseCards/components/ReuseCardItems/ReuseCardItems';
import { UpdateReuseCard } from '../modules/ReuseCards/components/UpdateReuseCard/UpdateReuseCard';
import { ReuseCards } from '../modules/ReuseCards/ReuseCards';
import ScriptsPage from '../modules/Scripts/ScriptsPage';
import { Routes } from './routes';

type RouterItem = {
  path: Routes;
  element?: React.ReactNode;
  render?: (props: any) => React.ReactNode;
  roles?: Role[];
};

export const ROUTER_ITEMS: RouterItem[] = [
  { path: Routes.HOME_PATH, element: <Home /> },
  { path: Routes.RESTAURANTS_PATH, element: <Restaurants /> },
  { path: Routes.CREATE_RESTAURANT_PATH, element: <CreateRestaurant /> },
  { path: Routes.UPDATE_RESTAURANT_PATH, element: <UpdateRestaurant /> },
  { path: Routes.CREATE_RESTAURANT_USER_PATH, element: <CreateRestaurantUser /> },
  { path: Routes.RESTAURANT_IMAGES_PATH, element: <RestaurantImages /> },
  { path: Routes.RESTAURANT_RETURN_CODES_PATH, element: <RestaurantReturnCodes /> },
  { path: Routes.CREATE_RESTAURANT_RETURN_CODES_PATH, element: <CreateRestaurantReturnCode /> },
  { path: Routes.UPDATE_RESTAURANT_RETURN_CODES_PATH, element: <UpdateRestaurantReturnCode /> },
  { path: Routes.MASS_EDIT_RESTAURANTS_PATH, element: <UpdateMultipleRestaurants /> },
  { path: Routes.MASS_EDIT_KEY_ACCOUNT_RESTAURANTS_PATH, element: <UpdateMultipleRestaurants /> },
  { path: Routes.RESTAURANT_TWO_FACTOR_RETURN_PATH, element: <TwoFactorReturn /> },
  { path: Routes.RESTAURANT_DEPOSIT_PATH, element: <Deposit /> },
  {
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    path: Routes.WAREHOUSES_PATH,
    element: <Warehouses />,
  },
  { path: Routes.CREATE_WAREHOUSE_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <CreateWarehouse /> },
  {
    path: Routes.UPDATE_WAREHOUSE_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <UpdateWarehouse />,
  },
  { path: Routes.TAGS_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <Tags /> },
  { path: Routes.CREATE_TAG_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <CreateTag /> },
  { path: Routes.UPDATE_TAG_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <UpdateTag /> },
  {
    path: Routes.CATEGORIES_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <Categories />,
  },
  { path: Routes.CREATE_CATEGORY_PATH, roles: [Role.ADMINISTRATOR], element: <CreateCategory /> },
  { path: Routes.UPDATE_CATEGORY_PATH, roles: [Role.ADMINISTRATOR], element: <UpdateCategory /> },
  { path: Routes.CITIES_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <CitiesPage /> },
  { path: Routes.CREATE_CITY_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <CreateCity /> },
  { path: Routes.UPDATE_CITY_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <UpdateCity /> },
  { path: Routes.NEWS_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <News /> },
  { path: Routes.CREATE_NEWS_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <CreateNews /> },
  { path: Routes.UPDATE_NEWS_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <UpdateNews /> },
  { path: Routes.ABOUT_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <AboutUs /> },
  { path: Routes.CREATE_ABOUT_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <CreateAboutUs /> },
  { path: Routes.UPDATE_ABOUT_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <UpdateAboutUs /> },
  {
    path: Routes.PROMOTIONS_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <PromotionsPage />,
  },
  {
    path: Routes.UPDATE_PROMOTION,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <PromotionFormPage />,
  },
  {
    path: Routes.USERS_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <ApplicationUsersPage />,
  },
  {
    path: Routes.RELEVO_USERS_PATH,
    roles: [Role.ADMINISTRATOR],
    element: <RelevoUsers />,
  },
  {
    path: Routes.CREATE_RELEVO_USER_PATH,
    roles: [Role.ADMINISTRATOR],
    element: <CreateRelevoUser />,
  },
  {
    path: Routes.UPDATE_RELEVO_USER_PATH,
    roles: [Role.ADMINISTRATOR],
    element: <RelevoUserPage />,
  },
  { path: Routes.USERS_ASSIGNED_ITEMS_PATH, element: <UsersAssignedItemsPage /> },
  { path: Routes.ASSIGNED_ITEMS_EXTEND_PATH, element: <ExtendAssignedItem /> },
  { path: Routes.USERS_PROFILE_SETTINGS_PATH, element: <ApplicationUserProfileSettings /> },
  {
    path: Routes.PAYMENTS_PATH,
    roles: [Role.ADMINISTRATOR],
    element: <PaymentsPage />,
  },
  {
    path: Routes.SCRIPTS_PATH,
    roles: [Role.ADMINISTRATOR],
    element: <ScriptsPage />,
  },
  {
    path: Routes.CORPORATES_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <Corporates />,
  },
  { path: Routes.CREATE_CORPORATE_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <CreateCorporate /> },
  { path: Routes.UPDATE_CORPORATE_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <UpdateCorporate /> },
  { path: Routes.PARTNERS_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <Partners /> },
  {
    path: Routes.CREATE_PARTNER_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <CreatePartner />,
  },
  {
    path: Routes.CREATE_PARTNERS_BATCH_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <CreatePartnersBatch />,
  },
  {
    path: Routes.CREATE_PARTNERS_BATCH_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <CreatePartner />,
  },
  { path: Routes.UPDATE_PARTNER_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <UpdatePartner /> },
  { path: Routes.NOTIFICATIONS_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <SendNotifications /> },
  {
    path: Routes.RESTAURANT_USERS_PATH,
    element: <RestaurantUsers />,
  },
  {
    path: Routes.UPDATE_RESTAURANT_USERS_PATH,
    element: <RestaurantUserPage />,
  },
  {
    path: Routes.RESTAURANT_USERS_NOTIFICATION_SETTINGS_PATH,
    element: <NotificationSettings />,
  },
  { path: Routes.KEY_ACCOUNT_GROUPS_PATH, element: <KeyAccountGroups /> },
  { path: Routes.CREATE_KEY_ACCOUNT_GROUP_PATH, element: <CreateKeyAccountGroup /> },
  { path: Routes.KEY_ACCOUNT_GROUP_RESTAURANTS_PATH, element: <KeyAccountGroupRestaurants /> },
  { path: Routes.KEY_ACCOUNT_GROUP_RETURN_CODES_PATH, element: <KeyAccountGroupReturnCodes /> },
  { path: Routes.DOWNLOAD_RESTAURANT_RETURN_CODES_PATH, element: <DownloadRestaurantReturnCode /> },
  { path: Routes.ADD_RESTAURANT_KEY_ACCOUNT_GROUP_PATH, element: <KeyAccountGroupAddRestaurant /> },
  { path: Routes.EDIT_KEY_ACCOUNT_GROUP_PATH, element: <KeyAccountGroupEdit /> },
  { path: Routes.KEY_ACCOUNT_GROUP_IMAGES_PATH, element: <KeyAccountGroupImages /> },
  { path: Routes.ASSIGN_ITEM, element: <AssignProduct /> },
  { path: Routes.LOST_ITEMS, element: <LostItems /> },
  { path: Routes.PRODUCTS, element: <Products /> },
  { path: Routes.GENERATE_PRODUCTS, element: <GenerateProducts /> },
  { path: Routes.REPORTS_PATH, element: <Reports />, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER] },
  {
    path: Routes.META_CATEGORIES_PATH,
    roles: [Role.ADMINISTRATOR],
    element: <MetaCategories />,
  },
  { path: Routes.CREATE_META_CATEGORY_PATH, roles: [Role.ADMINISTRATOR], element: <CreateMetaCategory /> },
  { path: Routes.UPDATE_META_CATEGORY_PATH, roles: [Role.ADMINISTRATOR], element: <UpdateMetaCategory /> },
  {
    path: Routes.CLOSING_SOURCES_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <ClosingSources />,
  },
  {
    path: Routes.CREATE_CLOSING_SOURCE_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <CreateClosingSource />,
  },
  {
    path: Routes.UPDATE_CLOSING_SOURCE_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <UpdateClosingSource />,
  },
  {
    path: Routes.CUSTOMER_SUMMARY_PATH,
    roles: [Role.ADMINISTRATOR, Role.LIMITED_USER, Role.STANDARD_USER],
    element: <CustomerSummary />,
  },
  {
    path: Routes.UPDATE_CUSTOMER_PATH,
    roles: [Role.ADMINISTRATOR, Role.LIMITED_USER, Role.STANDARD_USER],
    element: <UpdateCustomer />,
  },
  {
    path: Routes.CUSTOMERS_PATH,
    roles: [Role.ADMINISTRATOR, Role.LIMITED_USER, Role.STANDARD_USER],
    element: <Customers />,
  },
  {
    path: Routes.RESTAURANT_DRAFTS_PATH,
    roles: [Role.ADMINISTRATOR],
    element: <RestaurantDrafts />,
  },
  {
    path: Routes.RESTAURANT_DRAFT_DETAILS_PATH,
    roles: [Role.ADMINISTRATOR, Role.LIMITED_USER, Role.STANDARD_USER],
    element: <RestaurantDraft />,
  },
  {
    path: Routes.IMPORT_RESTAURANTS_PATH,
    roles: [Role.ADMINISTRATOR, Role.LIMITED_USER, Role.STANDARD_USER],
    element: <ImportRestaurants />,
  },
  {
    path: Routes.FEATURE_FLAGS_PATH,
    roles: [Role.ADMINISTRATOR],
    element: <FeatureFlagsPage />,
  },
  {
    path: Routes.TEST_NOTIFICATIONS_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <SendTestNotification />,
  },
  {
    path: Routes.FREQUENTLY_ASKED_QUESTIONS_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <FrequentlyAskedQuestionsPage />,
  },
  { path: Routes.CREATE_FAQ_SECTION_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <SectionCreate /> },
  { path: Routes.UPDATE_FAQ_SECTION_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <SectionUpdate /> },
  {
    path: Routes.CREATE_FAQ_QUESTION_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <QuestionCreate />,
  },
  {
    path: Routes.UPDATE_FAQ_QUESTION_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <QuestionUpdate />,
  },
  { path: Routes.REUSE_CARDS_PATH, roles: [Role.ADMINISTRATOR, Role.STANDARD_USER], element: <ReuseCards /> },
  {
    path: Routes.REUSE_CARDS_CREATE_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <CreateReuseCards />,
  },
  {
    path: Routes.REUSE_CARDS_UPDATE_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <UpdateReuseCard />,
  },
  {
    path: Routes.REUSE_CARDS_ASSIGNED_ITEMS_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    element: <ReuseCardItems />,
  },
];
